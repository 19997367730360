import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { server } from '../server';

import { Company_Id } from '../Helper'
import { Corporate_Id } from '../Helper'
import { Corporate_Name } from '../Helper'
import { Vendor_Id } from '../Helper'
import { Vendor_Name } from '../Helper'


function Login() {

  //local details-----------------
  //  const Corporate_ID = 13611;
  //  const Corporate_Name = 'O3MPLRetail';
  //  const Vendor_Id = 2584;
  //  const Vendor_Name = 'O3MPL';
  // local details -----------------

  localStorage.setItem('isLogin', false);
  localStorage.removeItem('isLogin');
  localStorage.removeItem('userid');
  localStorage.removeItem('userName');
  localStorage.removeItem('emailId');
  localStorage.removeItem('contactNo');




  // CompanyId: "13996",
  // CorporateId: "13647",
  // CorporateName: "O3MPLRetail",
  // VendorId: "2587",
  // VendorName:"O3MPL",
  //========== Local======================
  // CorporateId: "13611", 
  // CorporateName: "O3MPLRetail",
  // VendorId:"2584",
  // VendorName:"O3MPL"


  // const initialValues = {
  //   MobileNo: "", UserPass: "",
  //   CorporateId: _corporateId,
  //   CorporateName: _corporatename,
  //   VendorId: _vendorId,
  //   VendorName: _vendorName

  // }

  const [formvalue, setFormValue] = useState(
    {
      MobileNo: "", UserPass: "",
      CompanyId: "13996",
      CorporateId: "13647",
      CorporateName: "O3MPLRetail",
      VendorId: "2587",
      VendorName: "O3MPL",
    }

  );
  const [formErrors, setFormErrors] = useState({});
  const [IsSubmit, setIsSubmit] = useState(false);

  //debugger;
  const handChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formvalue, [name]: value });
    //console.log(formvalue);
  }
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formvalue));
    setIsSubmit(true);

  }
  useEffect(() => {

    if (Object.keys(formErrors).length == 0 && IsSubmit) {
      // console.log(formvalue);
      userLogin(formvalue);
    }
  }, [formErrors]);

  const validate = (value) => {
    const errors = {};
    if (!value.MobileNo) {
      errors.MobileNo = "Mobile No is required";
    }

    if (!value.UserPass) {
      errors.UserPass = "Password is required";
    }
    return errors;
  };

  async function userLogin(formvalue) {
    // debugger;
    // console.log('formvalue-' + formvalue);
    let result = await fetch(`${server}/api/O3Mobility/O3loginweb`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "text/plain"
      },
      body: JSON.stringify(formvalue)
    });
    result = await result.json();
    console.log(result);

    if ((result.statusCode === 200) && (result.statusMessage === 'Login Successfully')) {
      localStorage.setItem('isLogin', true);
      //localStorage.setItem('userid', formvalue.MobileNo);
      localStorage.setItem('userid', result.userId);
      localStorage.setItem('userName', result.userName);
      localStorage.setItem('emailId', result.emailId,);
      localStorage.setItem('contactNo', result.contactNo);
      //navigate('/Dashboard');

      let logingDetails = {
        userId: result.userId,
        userName: result.userName,
        emailId: result.emailId,
        contactNo: result.contactNo
      }

      // debugger;

      // { state: { formvalue } }
      //navigate('/Booking', { state: { logingDetails } });
      navigate('/Booking');
    }
    if ((result.statusCode === 100) && (result.statusMessage === 'Invalid Login')) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid Login !!',

      })
    }
  }

  return (

    <div className='hold-transition login-page'>
      <div className="login-box">
        <div className="card card-outline card-warning">
          <div className="card-header text-center">
            <img src="/dist/img/logo.webp" alt="logo" style={{ width: '250px' }} />
          </div>
          <div className="card-body">

            <p className="login-box-msg">Sign in to start your session</p>
            <form onSubmit={handleSubmit}>

              <div className="input-group mb-3">
                <input name="MobileNo" type="number" className="form-control" placeholder="Contact No"
                  value={formvalue.MobileNo}
                  onChange={handChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-mobile" />
                  </div>
                </div>
              </div>
              <p className='text-danger'>{formErrors.MobileNo}</p>
              <div className="input-group mb-3">
                <input name="UserPass" type="password" className="form-control" placeholder="Password"
                  value={formvalue.UserPass}
                  onChange={handChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>

              </div>
              <p className='text-danger'>{formErrors.UserPass}</p>
              <div className="row">
                {/* <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">
                      Remember Me
                    </label>
                  </div>
                </div> */}

                <div className="col-12">
                  <button className="btn btn-warning btn-block">Login</button>
                  {/* <Link to="/Dashboard" className="btn btn-warning btn-block">Sign In</Link> */}
                </div>
              </div>
            </form>
            <div className="social-auth-links text-center mt-2 mb-3">
            </div>

            <p className="mb-0">
              <Link to="/Registration" className="text-center text-danger">Register Now</Link>
              {/* <a href="register.html" className="text-center text-danger">Register now</a> */}
            </p>

          </div>
        </div>
      </div>
      <div>
        <Link to="/RefundPolicy" target='_blank' className="text-center text-danger" style={{ fontSize: '12px', color: '#5b5b55 !important' }}>Privacy Policy</Link>
        {/* <Link to="https://sites.google.com/view/eveelzgroundstaffprivacypolicy/home" target='_blank' className="text-center text-danger" style={{fontSize:'12px', color:'#5b5b55 !important'}}>Privacy Policy</Link> */}
      </div>
    </div>
  )

}

export default Login;


