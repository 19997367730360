import React, { useState, useEffect } from 'react'
import MasterPage from '../Templates/MasterPage';
import Footer from '../Templates/Footer';
import { server } from '../server'
import axios from 'axios';
import { Link, json, useNavigate, useLocation } from 'react-router-dom';

export default function Bookingconfirm() {
    debugger;
    const { state } = useLocation();

    const [loading2, setLoading2] = useState(false);
    const navigate = useNavigate();

    const backtobooking = (e) => {
        navigate('/Booking');
    }
    const handlePayment2 = () => {
        SubmitData(state.formvalue);
    }

    const handlePayment = async (e) => {
        debugger;
        const data = {

            // name: document.getElementById("name").innerHTML,
            // email: document.getElementById("email").innerHTML,
            // contactno: document.getElementById("contactno").innerHTML,
            // fromlocation: document.getElementById("fromlocation").innerHTML,
            // tolocation: document.getElementById("tolocation").innerHTML,
            // triptype: document.getElementById("triptype").innerHTML,
            // vehiclecategory: document.getElementById("vehiclecategory").innerHTML,
            // bookingdate: document.getElementById("bookingdate").innerHTML,
            // amount: document.getElementById("amount").innerHTML,
            // MUID: "MUID" + Date.now(),
            // transactionId: 'T' + Date.now(),


            CompanyId: state.formvalue.CompanyId,
            CorporateId: state.formvalue.CorporateId,
            CorporateName: state.formvalue.CorporateName,
            VendorId: state.formvalue.VendorId,
            VendorName: state.formvalue.VendorName,
            OperatorID: state.formvalue.OperatorID,
            CallerID: state.formvalue.CallerID,
            CallerName: state.formvalue.CallerName,
            TaxiCategoryId: state.formvalue.TaxiCategoryId,
            TaxiCategory: state.formvalue.TaxiCategory,
            RatePlanId: state.formvalue.RatePlanId,
            TariffId: state.formvalue.TariffId,
            Tariff: state.formvalue.Tariff,
            RequestPickUpTime: state.formvalue.RequestPickUpTime,
            NoOfDays: state.formvalue.NoOfDays,

            PickupAddress: state.formvalue.PickupAddress,
            PickupMessage: state.formvalue.PickupMessage,
            Pickuplat: state.formvalue.Pickuplat,
            Pickuplon: state.formvalue.Pickuplon,
            DropOffAddress: state.formvalue.DropOffAddress,
            DropOfflat: state.formvalue.DropOfflat,
            DropOfflon: state.formvalue.DropOfflon,
            TypeOfTripId: state.formvalue.TypeOfTripId,
            Noofpassengers: state.formvalue.Noofpassengers,
            NoOFVehicles: state.formvalue.NoOFVehicles,
            GuestEmailId: state.formvalue.GuestEmailId,
            GuestMobileNum: state.formvalue.GuestMobileNum,
            paymenttypeid: state.formvalue.paymenttypeid,
            paymenttype: state.formvalue.paymenttype,

            email: state.formvalue.email,
            BookingFare: state.formvalue.BookingFare,
            BookingType: state.formvalue.BookingType,
            CalculatedBaseFare: state.formvalue.CalculatedBaseFare,
            r1: state.formvalue.r1,
            MUID: "MUID" + Date.now(),
            transactionId: 'T' + Date.now(),
        }
        console.log('data--', data);
        e.preventDefault();
        setLoading2(true);

        try {
            // const response = await axios.post('https://payment.eveelz.in/api/payment', { ...data });
            const response = await axios.post('http://localhost:5000/api/payment', { ...data });
            if (response.data.sucess){
                window.location.href = response.data.url;
            }
            else{
                navigate('/failure')
            }
            console.log('response...pp',response.data)
          } catch (error) {

          }




        // //https://payment.eveelz.in/api/payment
        // //http://localhost:89/api/payment
        // axios.post('https://payment.eveelz.in/api/payment', { ...data }).then(res => {
        //     //alert(JSON.stringify(res));
        //     debugger;
        //     console.log(res);
        //     console.log(res.data);
        //     if (res.status == 200) {
        //         debugger;
        //         window.location.href = res.data;

        //         // "sucess": true,
        //         // "url": "https://mercury-t2.phonepe.com/transact/pg?token=OWRjZWIxYzQ2MDBhYTc0MmE2Nzk4Mjk4ZmU4ZWY2NWY2M2E4YzE3NGEyNTgwNjE5Zjk1Y2QyNDhkZDQxNTQxMzcyZjhkNjVkNTNjNDcyOTM4OTg1YjY4NDpiZjYwMzQxZGU4YjU5ZTMyZDQxMGFiMTQ2ZWMwMTFlNA"

        //         // SubmitData(state.formvalue);
        //     }
        // }).catch(error => {
        //     // setLoading2(false)
        //     //console.error(error);
        // });
    }

    async function SubmitData(formvalue) {
        //debugger;
        //
        //https://localhost:7291/api/O3Mobility/OnlineBooking
        // let result = await fetch(`https://localhost:7291/api/Booking/bookairportvechicle`,
        let result = await fetch(`${server}/api/Booking/bookairportvechicle`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "text/plain",
                },
                body: JSON.stringify(formvalue),
            }
        );
        console.log('res-', result);
        result = await result.json();
    }

    //pay cash...
    async function BookAndPayCash() {
        console.log('state.formvalue')
        console.log(state.formvalue)
        //debugger;
        //let result = await fetch(`${server}/api/Booking/bookairportvechicle`,
        //let result = await fetch(`https://localhost:7291/api/Booking/bookairportvechicle`,
        let result = await fetch(`${server}/api/Booking/bookairportvechicle`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "text/plain",
                },
                body: JSON.stringify(state.formvalue),
            }
        );
        console.log('res-', result);
        result = await result.json();
        console.log('cash response', result);
        if (result.statusCode === 200) {
            navigate('/success');
        }
    }

    return (
        <div>
            <>
                <div>
                    <MasterPage />
                </div>
                <div>
                    <div className="content-wrapper" style={{ minHeight: '704.8px' }}>
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Booking Confirmation</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Booking Confirmation</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>{/* /.container-fluid */}
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <div className="container-fluid">

                                <div className="card card-warning">
                                    <div className="card-header">
                                        <h3 className="card-title">Booking Confirmation Details</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <table className="table table-striped table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th scope="col" style={{ width: '20%' }}>Name</th>
                                                        <th scope="col"><label id="name" for="name"> {state.formvalue.CallerName} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Email id.</th>
                                                        <th scope="col"><label id="email" for="email">{state.formvalue.email} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Contact No.</th>
                                                        <th scope="col"><label id="contactno" for="contactno">{state.formvalue.CallerID} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Pickup Location</th>
                                                        <th scope="col"><label id="fromlocation" for="fromlocation">{state.formvalue.PickupAddress} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Drop Location</th>
                                                        <th scope="col"><label id="tolocation" for="tolocation">{state.formvalue.DropOffAddress} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Trip Type</th>
                                                        <th scope="col"><label id="triptype" for="triptype">{state.formvalue.Tariff} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Vehicle Category</th>
                                                        <th scope="col"><label id="vehiclecategory" for="vehiclecategory">{state.formvalue.TaxiCategory} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Request Pickup Date</th>
                                                        <th scope="col"><label id="bookingdate" for="bookingdate">{state.formvalue.RequestPickUpTime} </label></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Estimate Fare</th>
                                                        <th scope="col">₹<label id="amount" for="fare">{state.formvalue.BookingFare} </label></th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='row' style={{ marginTop: '10px' }}>
                                            <div className="col-md-3" style={{ textAlign: 'right' }}>
                                                <button onClick={backtobooking} className="btn btn-warning btn-block">Back </button>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <div className="input-group date" id="reservationdatetime" data-target-input="nearest">
                                                        {/* <input type="button" onClick={BookNow} className="btn btn-warning" value='Book a Ride' /> */}
                                                        <button onClick={handlePayment} className="btn btn-success btn-block">Confirm And Pay Online </button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <div className="input-group date" id="reservationdatetime" data-target-input="nearest">

                                                        <button onClick={BookAndPayCash} className="btn btn-outline-info btn-block">Confirm And Pay Cash </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>{/*/. container-fluid */}
                        </section>
                        {/* /.content */}
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </>
        </div>
    )
}
